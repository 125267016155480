import {
  AiOutlineHome,
  AiOutlineInfoCircle,
  AiOutlineQuestionCircle,
  AiOutlineForm,
  AiOutlineUserAdd,
  AiOutlineUsergroupAdd,
} from "react-icons/ai";

export const pageLinks = [
  {
    id: 1,
    href: "#home",
    text: "home",
    icon: <AiOutlineHome />,
  },
  {
    id: 2,
    href: "#about",
    text: "about us",
    icon: <AiOutlineInfoCircle />,
  },
  {
    id: 3,
    href: "#faqs",
    text: "FAQs",
    icon: <AiOutlineQuestionCircle />,
  },
  {
    id: 4,
    href: "#home",
    text: "Apply",
    icon: <AiOutlineForm />,
  },
  {
    id: 5,
    href: "#home",
    text: "Parents",
    icon: <AiOutlineUsergroupAdd />,
  },
  {
    id: 6,
    href: "#home",
    text: "Nanny",
    icon: <AiOutlineUserAdd />,
  },
];

export const socialLinks = [
  {
    id: 1,
    href: "https://www.facebook.com",
    name: "facebook",
  },
  {
    id: 2,
    href: "https://www.twitter.com",
    name: "twitter",
  },
  {
    id: 3,
    href: "https://www.instagram.com",
    name: "instagram",
  },
  {
    id: 4,
    href: "https://wa.me/353892644059?text=Hello there.%0A%0AI'd%20like%20to%20inquire%20about%20Perfect%20Match%20Nanny%20Agency...%0A%0A",
    name: "whatsapp",
  },
];

export const extraLinks = [
  {
    id: 1,
    href: "https://loosenthedark.tech/perfect-match-nanny-agency/terms-and-conditions",
    text: "terms",
  },
  {
    id: 2,
    href: "https://loosenthedark.tech/perfect-match-nanny-agency/cookie-policy",
    text: "cookie policy",
  },
  {
    id: 3,
    href: "https://loosenthedark.tech/perfect-match-nanny-agency/privacy-policy",
    text: "privacy policy",
  },
];

export const faqs = [
  // {
  //   id: 1,
  //   q: 'What is Perfect Match?',
  //   a: 'Perfect Match is Ireland’s newest professional nanny agency. We are committed to helping you find a warm, caring, responsible and experienced nanny, who will create a thriving and loving environment for your children.',
  // },
  {
    id: 1,
    q: "What's our background?",
    a: "We are a partnership of two experienced mothers. We have been working in the private childcare sector as nannies, maternity nurses and childminders for the last twelve years. One of us is a registered nurse. Being parents ourselves, we have an excellent perspective from both sides of the fence.",
  },
  {
    id: 2,
    q: "Why did we decide to start Perfect Match?",
    a: "The idea to set up this nanny agency evolved from the increasingly large number of parents who have approached us seeking the same very high standard of care for their children in their own homes as we have been providing to so many of the children that we have minded over the years.",
  },
  // {
  //   id: 4,
  //   q: "What's our approach?",
  //   a: 'As a boutique nanny agency, we have a devoted and personal approach. We offer a unique service, as we call to your home and meet with you in your own environment. We listen carefully to your needs and expectations in order to ensure your standards are met and our reputation is upheld. We then search for your "Perfect Match".',
  // },
  // {
  //   id: 5,
  //   q: 'How can I apply?',
  //   a: 'Once you have made initial contact with us, we will phone you for a chat and, if you wish to proceed, we will ask you to fill out a simple online form outlining your requirements. We will then arrange our visit to your home at a time of your choosing.',
  // },
  {
    id: 3,
    q: "How does our selection process work?",
    a: "All of our nannies have been interviewed by us in person prior to being chosen. They all have good English, nanny experience, excellent references which we have verified, Garda vetting and a Paediatric First Aid certificate.",
  },
  {
    id: 4,
    q: "What happens after I apply?",
    a: "We will then select a few carefully vetted nannies and send them to your home for an interview.",
  },
  // {
  //   id: 7,
  //   q: 'How can I be sure my nanny has been fully trained?',
  //   a: "Once you have chosen your Perfect Match nanny, we offer an optional service included in the placement fee where one of our partners, a registered nurse, will provide a full day's training with you and the nanny in your home before she officially starts. This is to ensure all of your care requirements for your children are understood and carried out to a very high standard. We believe that this is the most important part of our mission, and the one closest to our hearts.",
  // },
  // {
  //   id: 5,
  //   q: "What if I'm not happy with my nanny?",
  //   a: 'We value the importance of finding the most suitable person to become part of your family, and wish to maintain a long-lasting relationship with those we work with. We will remain available to you and your nanny for the duration of their employment, should you need any advice or to discuss any concerns you might have.',
  // },
];

export const formNannySteps = [
  {
    id: 1,
    text: "Your Details",
  },
  {
    id: 2,
    text: "Your Address",
  },
  {
    id: 3,
    text: "Your Eligibility",
  },
  {
    id: 4,
    text: "Your Mobility",
  },
  {
    id: 5,
    text: "Your Experience",
  },
  {
    id: 6,
    text: "Your Availability",
  },
  {
    id: 7,
    text: "Your Employment",
  },
  {
    id: 8,
    text: "Your CV",
  },
];

export const formParentsSteps = [
  {
    id: 1,
    text: "Your Details",
  },
  {
    id: 2,
    text: "Your Address",
  },
  {
    id: 3,
    text: "Your Children",
  },
  {
    id: 4,
    text: "Children Details",
  },
  {
    id: 5,
    text: "Core Requirements",
  },
  {
    id: 6,
    text: "Times Required",
  },
  {
    id: 7,
    text: "Additional Requirements",
  },
  {
    id: 8,
    text: "Your Agreement",
  },
];

export const nationalities = [
  "Afghan",
  "Albanian",
  "Algerian",
  "American",
  "Andorran",
  "Angolan",
  "Antiguans",
  "Argentinean",
  "Armenian",
  "Australian",
  "Austrian",
  "Azerbaijani",
  "Bahamian",
  "Bahraini",
  "Bangladeshi",
  "Barbadian",
  "Barbudans",
  "Batswana",
  "Belarusian",
  "Belgian",
  "Belizean",
  "Beninese",
  "Bhutanese",
  "Bolivian",
  "Bosnian",
  "Brazilian",
  "British",
  "Bruneian",
  "Bulgarian",
  "Burkinabe",
  "Burmese",
  "Burundian",
  "Cambodian",
  "Cameroonian",
  "Canadian",
  "Cape Verdean",
  "Central African",
  "Chadian",
  "Chilean",
  "Chinese",
  "Colombian",
  "Comoran",
  "Congolese",
  "Costa Rican",
  "Croatian",
  "Cuban",
  "Cypriot",
  "Czech",
  "Danish",
  "Djibouti",
  "Dominican",
  "Dutch",
  "East Timorese",
  "Ecuadorean",
  "Egyptian",
  "Emirian",
  "Equatorial Guinean",
  "Eritrean",
  "Estonian",
  "Ethiopian",
  "Fijian",
  "Filipino",
  "Finnish",
  "French",
  "Gabonese",
  "Gambian",
  "Georgian",
  "German",
  "Ghanaian",
  "Greek",
  "Grenadian",
  "Guatemalan",
  "Guinea-Bissauan",
  "Guinean",
  "Guyanese",
  "Haitian",
  "Herzegovinian",
  "Honduran",
  "Hungarian",
  "I-Kiribati",
  "Icelander",
  "Indian",
  "Indonesian",
  "Iranian",
  "Iraqi",
  "Irish",
  "Israeli",
  "Italian",
  "Ivorian",
  "Jamaican",
  "Japanese",
  "Jordanian",
  "Kazakhstani",
  "Kenyan",
  "Kittian and Nevisian",
  "Kuwaiti",
  "Kyrgyz",
  "Laotian",
  "Latvian",
  "Lebanese",
  "Liberian",
  "Libyan",
  "Liechtensteiner",
  "Lithuanian",
  "Luxembourger",
  "Macedonian",
  "Malagasy",
  "Malawian",
  "Malaysian",
  "Maldivan",
  "Malian",
  "Maltese",
  "Marshallese",
  "Mauritanian",
  "Mauritian",
  "Mexican",
  "Micronesian",
  "Moldovan",
  "Monacan",
  "Mongolian",
  "Moroccan",
  "Mosotho",
  "Motswana",
  "Mozambican",
  "Namibian",
  "Nauruan",
  "Nepali",
  "New Zealander",
  "Nicaraguan",
  "Nigerian",
  "Nigerien",
  "North Korean",
  "Northern Irish",
  "Norwegian",
  "Omani",
  "Pakistani",
  "Palauan",
  "Panamanian",
  "Papua New Guinean",
  "Paraguayan",
  "Peruvian",
  "Polish",
  "Portuguese",
  "Qatari",
  "Romanian",
  "Russian",
  "Rwandan",
  "Saint Lucian",
  "Salvadoran",
  "Samoan",
  "San Marinese",
  "Sao Tomean",
  "Saudi",
  "Scottish",
  "Senegalese",
  "Serbian",
  "Seychellois",
  "Sierra Leonean",
  "Singaporean",
  "Slovakian",
  "Slovenian",
  "Solomon Islander",
  "Somali",
  "South African",
  "South Korean",
  "Spanish",
  "Sri Lankan",
  "Sudanese",
  "Surinamer",
  "Swazi",
  "Swedish",
  "Swiss",
  "Syrian",
  "Taiwanese",
  "Tajik",
  "Tanzanian",
  "Thai",
  "Togolese",
  "Tongan",
  "Trinidadian/Tobagonian",
  "Tunisian",
  "Turkish",
  "Tuvaluan",
  "Ugandan",
  "Ukrainian",
  "Uruguayan",
  "Uzbekistani",
  "Venezuelan",
  "Vietnamese",
  "Welsh",
  "Yemenite",
  "Zambian",
  "Zimbabwean",
];
